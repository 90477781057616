


































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useAccount, useLoyalty, useI18n } from '@vf/composables';
import { replaceAll } from '@/helpers/replaceAll';
import useRootInstance from '@/shared/useRootInstance';
import LoyaltyRewardsExpiry from '@/components/smart/loyalty/LoyaltyRewardsExpiry.vue';

export default defineComponent({
  name: 'LoyaltyRewardsExpiringBanner',
  components: {
    LoyaltyRewardsExpiry,
  },
  setup() {
    const { root } = useRootInstance();
    const { rewards } = useLoyalty(root);

    const isLoyaltyRewardsExpiringBannerHidden = ref(
      sessionStorage.getItem('isLoyaltyRewardsExpiringBannerHidden')
    );

    if (
      !root.$viewport.isSmall ||
      isLoyaltyRewardsExpiringBannerHidden.value ||
      !rewards.value
    ) {
      return {
        isLoyaltyRewardsExpiringBannerHidden: true,
      };
    }

    const { basicInformation } = useAccount(root);
    const { currencySymbol } = useI18n(root);

    const totalRewardsApplicableAmount = computed(() => {
      const reward = [...rewards.value.applicableLoyaltyVouchers]
        .reverse()
        .find(({ available }) => available)?.amountInCurrency;
      return root.$formatPrice(reward || 0, currencySymbol.value);
    });

    const toastContent = computed(() => {
      const { firstName } = basicInformation.value || {};
      return replaceAll(
        root.$t(
          firstName
            ? 'loyaltyRewardsAvailableToast.greetingWithName'
            : 'loyaltyRewardsAvailableToast.greeting'
        ) as string,
        {
          firstName,
          currencyAmount: totalRewardsApplicableAmount.value,
        }
      );
    });

    const hasExpiringPoints = computed(
      () =>
        rewards.value.expiringLoyaltyPoints &&
        rewards.value.expiringLoyaltyPointsDays <= 30
    );

    const closeBanner = () => {
      isLoyaltyRewardsExpiringBannerHidden.value = 'true';
      sessionStorage.setItem('isLoyaltyRewardsExpiringBannerHidden', 'true');
    };

    return {
      isLoyaltyRewardsExpiringBannerHidden,
      toastContent,
      hasExpiringPoints,
      rewards,
      closeBanner,
    };
  },
});
